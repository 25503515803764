'use strict';

Gri.module({
  name: 'breadcrumb-alpha',
  ieVersion: null,
  $el: $('.breadcrumb-alpha'),
  container: '.breadcrumb-alpha',
  fn: function () {
    // Variables
    const item = $('.breadcrumb-alpha');
    // Functions
    function breadcrumbSticky() {
      console.log('init');
      $(window).on('scroll', function () {
        if($(window).scrollTop() > $('.header-alpha').height() + 25){
          item.addClass('fixed');
        }else{
          item.removeClass('fixed');
        }
      });
    }
    //Init
    breadcrumbSticky();
  }
});
